import React from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@mui/material';

import SettingsDrawer from './components/SettingsDrawer';
import SplashScreen from './components/SplashScreen';

import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';

import routes from './routes';
import { createCustomTheme } from './theme';

import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();

  useScrollReset();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  return <QueryClientProvider
    client={queryClient}
  >
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster position="top-center" />
      { process.env.NODE_ENV === 'development' && <SettingsDrawer /> }
      {auth.isInitialized ? content : <SplashScreen />}
    </ThemeProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
}

export default App;
