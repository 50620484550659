import React from 'react'
import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import { List, Box } from '@mui/material';
import NavItem from './NavItem';

const reduceChildRoutes = ({ acc, pathname, item, depth }) => {
  const key = `${item.title}-${depth}`;

  const exactMatch = item.path ? !!matchPath({
    path: item.path,
    end: false
  }, pathname) : false;

  if (item.children) {
    const partialMatch = item.path ? !!matchPath({
      path: item.path,
      end: false
    }, pathname) : false;

    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
      />
    );
  }

  return acc;
}

const renderNavItems = ({
  depth = 0,
  items,
  pathname
}) => (
  <List
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
    }}
    disablePadding
  >
    {items.reduce(
      // eslint-disable-next-line no-use-before-define
      (acc, item) => reduceChildRoutes({
        acc,
        item,
        pathname,
        depth
      }), []
    )}
  </List>
)

const NavSectionTop = ({
  items,
  pathname
}) => {
  return (
    <Box
      display='flex'
      alignContent='center'
      justifyContent='center'
      sx={{
        // paddingTop: '18px',
        width: '100%',
        // marginLeft: '-108px'
      }}
    >
      {renderNavItems({
        items,
        pathname
      })}
    </Box>
  )
}

NavSectionTop.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string
}

export default NavSectionTop;
